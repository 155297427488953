import { ascendBy } from './ascendBy';
import { clearArray } from './clear-array';
import MsDyn365 from '@msdyn365-commerce/core';

interface createUtagQueueResult {
    utagQueue: object[],
    stopQueuing: () => void;
}

export const createUtagQueue = (sortBy: string, batchingInterval: number):createUtagQueueResult|undefined => {
    const utagQueue: object[] = [];
    if (MsDyn365.isBrowser) {
        const id = window.setInterval(() => {
            if (utagQueue.length) {
                //@ts-ignore
                utag.link(
                    utagQueue.sort(ascendBy(sortBy)).reduce((fullUtagObject, singleItemUtagObject) => {
                        Object.entries(singleItemUtagObject).forEach(([prop, val]) => {
                            if (typeof val === 'string') {
                                fullUtagObject[prop] = val;
                            } else if (Array.isArray(val)) {
                                if (typeof fullUtagObject[prop] === 'undefined') {
                                    fullUtagObject[prop] = [];
                                }
                                fullUtagObject[prop].push(val[0]);
                            }
                        });

                        return fullUtagObject;
                    }, {})
                );
                clearArray(utagQueue);
            }
        }, batchingInterval);
        const stopQueuing = () => {
            window.clearInterval(id);
        };
        return { utagQueue, stopQueuing };
    }
    return;
};
