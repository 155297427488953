import * as React from 'react';
import { ProductPrice } from '@msdyn365-commerce/retail-proxy';
import {
    ICoreContext,
    IRequestContext
} from '@msdyn365-commerce/core';
import { format } from '@msdyn365-commerce-modules/utilities';
import { RatingComponent } from '@msdyn365-commerce/components';
import { buildProductBadge, parseBadgeData } from '../../../Utilities/get-product-badge';
import {
    IGridSettings,
    IImageData,
    IImageSettings,
    Image
} from '@msdyn365-commerce/core';
import { generateImageUrl } from '@msdyn365-commerce-modules/retail-actions';
import { useInView } from 'react-intersection-observer';
import { urlFallBackImage } from '../../../Utilities/fallbackImageUrl';

export interface IUnbxdProductCarouselItemProps {
    context: ICoreContext
    productUrl: string,
    product: any,
    position: number
    ratingAriaLabel: string,
    startingAtText: string,
    callUnbxdClickEvent: any,
    brandName?: string,
    onView?(position: number): void,
    emptyPlaceHolderImage: string
}
export const UnbxdProductCarouselItem: React.FC<IUnbxdProductCarouselItemProps> = ({
    context,
    productUrl,
    product,
    position,
    brandName,
    ratingAriaLabel,
    startingAtText,
    callUnbxdClickEvent,
    onView,
    emptyPlaceHolderImage
}) => {

    function renderProductBadge(
        attributeName: string | undefined,
        requestContext: IRequestContext
    ): JSX.Element | undefined {
        const badgeData = attributeName;
        if (!badgeData) {
            return;
        }
        // split badge data into its separate badges
        const badges = badgeData.split(',');
        // parse badge data to get its schedule and image src
        const parsedBadges = badges.map(badge => parseBadgeData(badge, requestContext));

        // build product badge image components
        const finalizedBadges = parsedBadges.map(parsedBadge => parsedBadge && buildProductBadge(parsedBadge, requestContext));

        return finalizedBadges ? <>{finalizedBadges}</> : <></>;
    }
    function getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
        if (rating && ratingAriaLabel) {
            const roundedRating = rating.toFixed(2);
            return format(ratingAriaLabel || '', roundedRating, '5');
        }
        return '';
    }
    function renderRecsImage(
        imageSettings?: IImageSettings,
        gridSettings?: IGridSettings,
        imageUrl?: string,
        fallbackImageUrl?: string,
        altText?: string
    ): JSX.Element | null {
        if (!fallbackImageUrl || !gridSettings || !imageSettings) {
            return null;
        }
        const img: IImageData = {
            src: imageUrl ? imageUrl : fallbackImageUrl,
            altText: altText ? altText : '',
            fallBackSrc: fallbackImageUrl
        };
        const imageProps = {
            gridSettings,
            imageSettings,
            fallbackImageUrl
        };
        imageProps.imageSettings.cropFocalRegion = true;
        return <Image {...img} {...imageProps} imageFallbackOptimize={true} loadFailureBehavior='empty' />;
    }
    function renderRating(
        context: ICoreContext,
        typeName: string,
        id: string,
        avgRating?: number,
        totalRatings?: number,
        ariaLabel?: string
    ): JSX.Element | null {
        const numRatings = (totalRatings && totalRatings.toString()) || '0';
        if (avgRating && avgRating !== 0) {
            const ratingAriaLabel = getRatingAriaLabel(avgRating, ariaLabel);
            return (
                <RatingComponent
                    context={context}
                    id={id}
                    typeName={typeName}
                    avgRating={Math.round(avgRating * 10) / 10}
                    ratingCount={numRatings}
                    readOnly={true}
                    ariaLabel={ratingAriaLabel}
                    data={{}}
                />
            );
        } else {
            return null;
        }
    }
    function renderPrice(
        basePrice?: number,
        lowest_variant_adjustedPrice?: number,
        CustomerContextualPrice?: number
    ): JSX.Element | null {
        const price: ProductPrice = {
            BasePrice: basePrice,
            AdjustedPrice: lowest_variant_adjustedPrice,
            CustomerContextualPrice: CustomerContextualPrice
        };

        return basePrice ? (
            <div className="product-price">
                <p className="product-price-label">{startingAtText}</p>
                {price.AdjustedPrice && price.CustomerContextualPrice && price.CustomerContextualPrice < price.AdjustedPrice ? <>
                    <span className="product-dollars">${price.CustomerContextualPrice}</span>
                    <span className="product-original">
                        <span className="product-original-price">
                            <span className="product-dollars">${price.AdjustedPrice}</span>
                        </span>
                    </span>
                </> :
                    <span className="product-original price-text">
                        <span className="product-dollars">${price.BasePrice}</span>
                    </span>}
            </div>
        ) : null;
    }
    const imageUrl = generateImageUrl(product.PrimaryImageUrl, context.request.apiSettings);
    const fallBackImageUrl = urlFallBackImage(emptyPlaceHolderImage, product?.uniqueId, context.actionContext.requestContext);
    const defaultImageSettings: IImageSettings = {
        viewports: {
            xs: { q: `w=0&h=210&m=6`, w: 0, h: 0 },
            lg: { q: `w=0&h=210&m=6`, w: 0, h: 0 },
            xl: { q: `w=0&h=210&m=6`, w: 0, h: 0 }
        },
        lazyload: true
    };

    const [mscProductRef, inView] = useInView({
        threshold: .6,
        triggerOnce: true //This doesn't work reliably - the children in the carousel remount unexpectedly
    });
    React.useEffect(() => {
        if (inView && onView) {
            onView(position);
        }
    }, [inView]);

    return (
        <div className='msc-product' ref={mscProductRef}>
            <a
                href={productUrl}
                aria-label={product.ProductName}
                onClick={(e) => {
                    e.preventDefault();
                    callUnbxdClickEvent(productUrl, product.uniqueId, position + 1);
                }}
            >
                <div className='mfrm-product-badge'>
                    {renderProductBadge(product.Attr_5637149147, context.request)}
                </div>
                <div className='msc-product__image'>
                    {renderRecsImage(
                        defaultImageSettings,
                        context.request.gridSettings,
                        imageUrl,
                        fallBackImageUrl,
                        product.ProductName)}
                </div>
                <div className='msc-product__details'>
                    <a href={productUrl} aria-label={product.ProductName}>
                        <span className='msc-product__title' title={product.ProductName}>
                            {`${brandName} ${product.ProductName}`}
                        </span>
                        <div className='product-price-section'>
                            {renderPrice(
                                product.BasePrice,
                                product.lowest_variant_adjustedPrice,
                                product.CustomerContextualPrice)}
                        </div>
                    </a>
                    <div className='msc-product__rating-holder'>
                        {renderRating(context, "mfrm-product-recommendation-carousel", `${product.uniqueId}`, product.AverageRatingOverall, product.AverageRatingCountOverall, ratingAriaLabel)}
                    </div>
                    <div className='shop-ATC-buttons'>
                        <a className="shop-now-btn" href={productUrl}>Shop Now</a>
                    </div>
                </div>
            </a>
        </div>
    );
};

export default UnbxdProductCarouselItem;